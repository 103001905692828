import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as EventEmitter from 'events';
import { Registration } from '../models/details.model';
import { VALIDATIONMSG } from '../models/purchase.model';
import { PurchaseDataService } from '../services/purchase-data.service';
import { PurchaseService } from '../services/purchase.service';
import { STATESWITHCODE } from './models/state.model';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Input() forms: FormGroup;
  public MSG = VALIDATIONMSG;

  public registration = new Registration();
  regularExpression =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  allStates: string[];
  // showPrimaryEmailValidation: boolean;
  reEmail: string;
  // showSeccEmailValidation: boolean;
  isBlankEmail: boolean;
  hideDemographicInfo = false;
  constructor(
    private purchaseDataService: PurchaseDataService,
    private purchaseService: PurchaseService
  ) {
    this.reEmail = '';
    //this.showPrimaryEmailValidation = false;
    //this.showSeccEmailValidation = false;
    this.isBlankEmail = false;
    this.registration = new Registration();
    this.allStates = STATESWITHCODE.map(
      (state: any) => state.abbreviation
    ).sort((s1, s2) => s1.localeCompare(s2));
    this.purchaseService.route.subscribe((route) => {
      const promo = route.toUpperCase();
      let year = `${new Date().getFullYear()}`
      let aliasLastTwoChars = promo.substring(1); // Get the last two characters
      let aliasLastTwoCharsUpper = aliasLastTwoChars.toUpperCase();

     
        this.hideDemographicInfo =
       
          aliasLastTwoCharsUpper === 'BF' ||
          aliasLastTwoCharsUpper === 'DF' ||
          aliasLastTwoCharsUpper === 'DA' ||
          aliasLastTwoCharsUpper === 'PA' ||
          aliasLastTwoCharsUpper === 'PH' ||
          aliasLastTwoCharsUpper === 'HJ' ||
          aliasLastTwoCharsUpper === 'BC' ||
          aliasLastTwoCharsUpper === 'BD' ||
          aliasLastTwoCharsUpper === 'BJ' ||
          aliasLastTwoCharsUpper === 'BM' ||
          aliasLastTwoCharsUpper === 'BH';
        

    });
  
  }

  ngOnInit(): void {
    if (this.purchaseDataService.details.value) {
      this.registration = this.purchaseDataService.details.value;
      this.reEmail = this.purchaseDataService.details.value.email;
    }
  }

  changeInReg(controlName: string, value: any, event = null) {
    if (this.forms.get(controlName)) {
      this.forms.get(controlName).setValue(value);
    }
  }

  DropdownOpen() {
    document.documentElement.style.setProperty('--display-value', 'block');
  }
  DropdownClose() {
    document.documentElement.style.setProperty('--display-value', 'none');
  }

  assignCodeToModel(event) {
    this.registration.state = this.getCodeFromState(event.target.value);
    this.changeInReg('state', this.registration.state);
  }

  getCodeFromState(state: string) {
    const filteredItem = STATESWITHCODE.find((item) => {
      if (
        item &&
        item.name &&
        typeof item.name === 'string' &&
        item.name.length > 0
      ) {
        if (
          item.name.replace(' ', '').toLowerCase() ===
          state.replace(' ', '').toLowerCase()
        ) {
          return item.abbreviation;
        }
      }
    });
    if (filteredItem) {
      return filteredItem.abbreviation;
    }
    return '';
  }
}
