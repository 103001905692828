import { Component, OnInit } from '@angular/core';
import { Platform } from '../models/platform.model';
import { PurchaseDataService } from '../services/purchase-data.service';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
})
export class PlatformComponent implements OnInit {
  public platform = new Platform();
  constructor(private purchaseDataService: PurchaseDataService) {
    this.platform = {
      os: '',
    };
  }
  get isMacDisabled(): boolean {
    let url = document.location.href.split('/');
    let promoCode = url[url.length - 1];
    if (promoCode) {
      switch (promoCode.toLocaleUpperCase()) {
        case 'CRH':
        case 'CWH':
        case 'CZH':
        case 'CCH':
        case 'CPH':
        case 'DRH':
        case 'DWH':
        case 'DZH':
        case 'DCH':
        case 'DPH':
        case 'DBH':
        case 'ERH':
        case 'EWH':
        case 'EZH':
        case 'ECH':
        case 'EPH':
        case 'EBH':

          return true;
        default:
          return false;
      }
    }
  }

  ngOnInit(): void {
    if (this.purchaseDataService.platf.value) {
      this.platform = this.purchaseDataService.platf.value;
    }
    this.findPlatform();
    this.changeInPlat();
  }
  changeInPlat() {
    this.purchaseDataService.platf.next(this.platform);
  }
  findPlatform() {
    if (
      navigator.platform === 'Mac68K' ||
      navigator.platform === 'MacPPC' ||
      navigator.platform === 'MacIntel'
    ) {
      this.platform.os = 'M';
    } else {
      this.platform.os = 'W';
    }
  }
}
