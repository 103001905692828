import { HttpClient, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivationCodeComponent } from '../activation-code/activation-code.component';
import { DetailsComponent } from '../details/details.component';
import { ConfirmEmailValidator, Purchase } from '../models/purchase.model';
import { PlatformComponent } from '../platform/platform.component';
import { DownloadFileService } from '../services/download-file.service';
import { PurchaseDataService } from '../services/purchase-data.service';
import { PurchaseService } from '../services/purchase.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceCheckComponent } from 'src/app/device-check/device-check.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { ReCaptchaService } from 'angular-recaptcha3';
import { LoggingService } from 'src/app/shared/services/logging.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  @ViewChild('detailComponent', { static: false })
  public _registration: DetailsComponent;

  @ViewChild('activationcode', { static: false })
  public _activationCode: ActivationCodeComponent;

  @ViewChild('platform', { static: false })
  public _platform: PlatformComponent;

  @ViewChild('contentBody', { static: false })
  public _contentBody: ElementRef;

  purchase: Purchase;
  platformParm: string;
  isDownloading: boolean;
  disableDownload: boolean;
  postDownloadMsg: string;
  success: boolean;
  msgTheme: string;
  showLoader: boolean;
  processMsg = 'Click below to download your file.';
  successMsg = 'Congratulations, your software has downloaded successfully!';
  greenTheme = 'medium-dark-green';
  yellowTheme = 'medium-light-yellow';
  percentDone: number = 0;
  bookImg: string;
  isNextPage: boolean;
  titleImage: string;
  showDownArrow: boolean;
  isChrome: boolean;
  isGoBack: boolean;
  fileName: any;
  resDevice: boolean;
  isRouteCHJ = false;
  isRouteBestBuy = false;
  buttonClick = 0;
  public parameterValue: any;
  public downloadData: any;
  public myModal: any;
  public step1Message: string;
  public step2Message: string;
  public step3Message: string;
  public singnUpModal: any;
  public singnUpModal2: any;
  public autoRenewal: boolean = false;
  clientIP = ''
  public purchaseForm: FormGroup;
  public siteKey;
  recaptchaload: boolean;
  public sku: string;
  public subscriptionYear: string;
  public subscriptionYearPlusOne: string;

  public stepsYear: string

  constructor(
    private purchaseService: PurchaseService,
    private router: ActivatedRoute,
    private purchaseDataService: PurchaseDataService,
    private downloadFileService: DownloadFileService,
    private navigateRoute: Router,
    private deviceService: DeviceDetectorService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private recaptchaService: ReCaptchaService,
    private loggingservice: LoggingService,
    private httpClient: HttpClient,

  ) {
    this.isDownloading = false;
    this.postDownloadMsg = this.processMsg;
    this.showLoader = false;
    this.purchase = new Purchase();
    this.bookImg = 'assets/icons/Win_B1.png';
    this.titleImage = 'assets/icons/logo.png';
    this.isNextPage = false;

    this.showDownArrow = false;
    this.isChrome =
      window.navigator.userAgent.toLowerCase().indexOf('edg') > -1
        ? false
        : true;
    this.fileName = '';
    this.resDevice = true;
    window.scrollTo(0, 0);

    this.loggingservice.logPageView('PurchaseComponent');
  }

  ngOnInit(): void {

    this.siteKey = AppConfig.config.recaptchaKey;
    this.parameterValue = this.router.snapshot.routeConfig.path;
    let deviceComponent = new DeviceCheckComponent(
      this.deviceService,
      this.navigateRoute
    );
    this.resDevice = deviceComponent.deviceFunction();
    this.detectpromode();
    this.checkAutorenewalStatus({ sku: "" }).then((response) => { });
    this.initForm();
    this.subscriptionYear = AppConfig.config.year
    let subscriptionYearNumber = Number(this.subscriptionYear) ; // Convert string to number
    this.subscriptionYearPlusOne = (subscriptionYearNumber + 1).toString(); // Add 1 and convert back to string
  }

  initForm() {
    this.purchaseForm =
      this.isRouteCHJ || this.isRouteBestBuy
        ? this.formBuilder.group({
          activationCode: [
            '',
            [
              Validators.required,
              Validators.maxLength(11),
              Validators.minLength(11),
            ],
          ],
          registration: this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            confirmEmail: [
              '',
              [
                Validators.required,
                Validators.email,
                ConfirmEmailValidator('email'),
              ],
            ],
            emailOptIn: [false, []],
          }),
        })
        : this.formBuilder.group({
          activationCode: [
            '',
            [
              Validators.required,
              Validators.maxLength(11),
              Validators.minLength(11),
            ],
          ],
          registration: this.formBuilder.group({
            address: ['', [Validators.required]],
            city: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            confirmEmail: [
              '',
              [
                Validators.required,
                Validators.email,
                ConfirmEmailValidator('email'),
              ],
            ],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            state: ['', [Validators.required]],
            zip: ['', [Validators.required]],
            emailOptIn: [false, []],
          }),
        });

    this.recaptchaload = true;
  }

  detectpromode() {
    if (this.resDevice) {
      this.router.params.subscribe((params) => {
        let url = document.location.href.split('/');

        let promo = url[url.length - 1];
        if (promo?.includes('?') && this.promoCodeCheck(promo.split("?").slice()[0])) {
          promo = promo.split("?").slice()[0]

        }
        this.purchaseService.setRoute(promo);
        let year = `${new Date().getFullYear()}`
    

        if (promo) {
          this.purchase.promoCode = promo.toLocaleUpperCase();
          this.bookImg = 'assets/icons/';
          let aliasFirstChar = "";
          let aliasLastTwoChars = "";
          if (promo) {
            if (promo.length >= 3) {
              aliasFirstChar = promo[0];
              aliasLastTwoChars = promo.substring(1); // Get the last two characters

              console.log("aliasFirstChar:", aliasFirstChar);
              console.log("aliasLastTwoChars:", aliasLastTwoChars);
            } else {
              console.error("Input string must have at least 3 characters.");
            }

          }


            this.promoBasedOnAlias(aliasFirstChar, aliasLastTwoChars, promo)














        } else {
          this.navigateRoute.navigate(['']);
        }
      });
      this.purchaseDataService.getActCode().subscribe((act) => {
        if (act) {
          this.purchase.activationCode = act.activationCode;
        }
      });
      this.purchaseDataService.getDetails().subscribe((det) => {
        if (det) {
          this.purchase.registration = det;
        }
      });
      this.purchaseDataService.getPlatf().subscribe((plat) => {
        if (plat) {
          this.purchase.OSType = plat.os;
          this.getInfoForSteps();
        }
      });
    }
    // set values
    var contextData = {
      page_section: 'hrblock',
      page_sub_section: 'activate',
      page_title: 'get started',
      software_activation: this.purchase.promoCode,
    };

    // Invoke Analytics Function
    _dd('page_load', contextData);
  }

  private getInfoForSteps() {
      let aliasFirstCharUpper = this.purchase.promoCode[0].toLocaleUpperCase();
    this.stepsYear = `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].year}`;
    if (this.purchase.OSType.toUpperCase() === 'W') {
      if (this.isChrome) {
        this.step1Message = `Find and double-click the downloaded file
              ${this.fileName} or open the file from the downloads
            list at the bottom left corner of this
            window.`;
      } else if (!this.isChrome) {
        this.step1Message = `Find and double-click the downloaded file
              ${this.fileName} or open the file from the downloads
             list at the top right corner of this window.`;
      }

      (this.step2Message = `If prompted, click “Install” and “Yes” on the system
            dialogs.`),
        (this.step3Message = `Once the installation has finished, your tax software is ready to launch.`);
    } else {
      this.step1Message = 'Double-click the downloaded .dmg file.';
      this.step2Message = 'Follow the on-screen instructions to install';
      (this.step3Message = 'Open your H&R Block' + " " + this.stepsYear + " " + 'software by double-clicking the icon in the Applications folder.');
    }
  }
  downloadURL: string;

  downloadSoftware() {
    this.showLoader = true;
    this.purchase.activationCode = this.purchase.activationCode.replace(
      '-',
      ''
    );
    this.purchase.OSType = this.purchase.OSType;
    this.purchase.year = `${new Date().getFullYear()}`; //to be removed later

    this.isGoBack = false;
    this.purchaseService.downloadDetails(this.purchase).subscribe(
      (data) => {
        if (data && data.success) {
          this.success = true;
          this.msgTheme = this.greenTheme;
          this.postDownloadMsg = this.processMsg;
          if (data.url) {
            this.downloadData = data;
            this.percentDone = 0;
            this.showLoader = true;
            // window.open(data.url, '_blank');
            this.downloadFileService.downloadFile(data.url).subscribe((x) => {
              if (x) {
                this.showLoader = true;
                if (x.type === HttpEventType.DownloadProgress) {
                  this.percentDone = Math.round((100 * x.loaded) / x.total);
                }
                if (x.type === HttpEventType.Response) {
                  // this.generateDownload(x.body);
                  this.postDownloadMsg = this.processMsg;
                  URL.createObjectURL(x.body);
                  let downloadLink = document.createElement('a');
                  downloadLink.href = window.URL.createObjectURL(x.body);
                  let filedetails = data;
                  let name1 = data.url.substring(
                    data.url.lastIndexOf('/') + 1,
                    data.url.length
                  );
                  let name = data.url.split('/');
                  this.fileName = name1;
                  downloadLink.setAttribute('download', name[name.length - 1]);
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  this.postDownloadMsg = this.successMsg;
                  this.showLoader = false;
                  if (this.autoRenewal) {
                    this.singnUpModal.show();
                  }
                }
              } else {
                this.isGoBack = true;
                this.success = false;
                this.showLoader = false;
                this.msgTheme = this.yellowTheme;
                this.postDownloadMsg = data.error;
              }
            });
          }
        } else {
          this.isGoBack = true;
          this.success = false;
          this.showLoader = false;
          this.msgTheme = this.yellowTheme;
          this.postDownloadMsg = data.error;
        }
      },
      (error) => {
        console.log(error);
        this.loggingservice.logException(error);
        this.showLoader = false;
      }
    );
  }
  showAnimation() {
    this.showDownArrow = true;
    setTimeout((x) => {
      this.showDownArrow = false;
    }, 5000);
  }

  goBack() {
    this.isNextPage = false;
    this.isGoBack = false;
    this.postDownloadMsg = '';
  }

  nextPage() {
    this.purchaseForm.markAllAsTouched();
    this.purchaseForm.markAsDirty();
    this.cdr.detectChanges();
    if (this.purchaseForm.invalid || this.showLoader) {
      return;
    }

    this.myModal = document.getElementById('errorModal');
    this.postDownloadMsg = '';
    this.showLoader = true;
    this.recaptchaService.execute({ action: 'login' }).then((token) => {
      // Backend verification method
      this.purchaseService.verifyRecaptcha(token).subscribe(
        (resp) => {
          if (resp) {
            this.validatedownloadSoftwareDetails();
          }
        },
        (error) => {
          console.log(error);
          this.loggingservice.logException(error);
          this.isNextPage = false;
          this.showLoader = false;
          this.postDownloadMsg = 'Network Error , Please try again later.';
          this.myModal.show();

        }
      );
    });
  }

  public validatedownloadSoftwareDetails() {
    this.showLoader = true;
    this.purchase = {
      ...this.purchase,
      year: `${new Date().getFullYear()}`,
      activationCode: this.purchaseForm.value.activationCode.replace('-', ''),
      registration: this.purchaseForm.controls.registration.value,
    };
    this.purchase.activationCode =
      this.purchaseForm.value.activationCode.replace('-', '');
    this.isGoBack = false;
    this.purchaseService.downloadDetails(this.purchase).subscribe(
      (data) => {
        if (data && data.success) {
          this.success = true;
          if (data.url) {
            window.scrollTo(0, 0);
            this.showLoader = false;
            this.downloadData = data;
            this.isNextPage = true;
            // set values
            var contextData = {
              page_section: 'hrblock',
              page_sub_section: 'activate',
              page_title: 'software confirmation',
            };

            // Invoke Analytics Function
            _dd('page_load', contextData);
          }
        } else {
          this.isNextPage = false;
          this.success = false;
          this.showLoader = false;
          this.postDownloadMsg = data.error;
          this.myModal.show();
        }
      },
      (error) => {
        console.log(error);
        this.loggingservice.logException(error);
        this.isNextPage = false;
        this.showLoader = false;
      }
    );
  }
  public downloadProgress(x) {
    if (x.type === HttpEventType.DownloadProgress) {
      this.percentDone = Math.round((100 * x.loaded) / x.total);
    }
    if (x.type === HttpEventType.Response) {
      this.postDownloadMsg = this.processMsg;
      this.finalDownload(x);
    }
  }

  public finalDownload(x) {
    if (x.body.type != 'binary/octet-stream' && this.buttonClick >= 7) {
      this.singnUpModal2.show();
      this.showLoader = false;
    } else {
      URL.createObjectURL(x.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(x.body);
      let name1 = this.downloadData.url.substring(
        this.downloadData.url.lastIndexOf('/') + 1,
        this.downloadData.url.length
      );
      let name = this.downloadData.url.split('/');
      this.fileName = name1;
      downloadLink.setAttribute('download', name[name.length - 1]);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.postDownloadMsg = this.successMsg;
      this.showLoader = false;
      if (this.autoRenewal) {
        this.singnUpModal.show();
      }

      if (this.postDownloadMsg === this.successMsg) {
        console.log("within loadip function")
        this.httpClient.get('https://jsonip.com').subscribe(
          (value: any) => {
            console.log(value);
            this.clientIP = value.ip;
            console.log("Ipaddress of software downloded user", this.clientIP)
            this.loggingservice.logTrace("Ipaddress of software downloded user", { ["ip"]: this.clientIP })
          },
          (error) => {
            console.log(error);
          }
        );

      }
    }
  }
  public downloadFile() {
    this.singnUpModal = document.getElementById('paymentModal');
    this.singnUpModal2 = document.getElementById('sinUpModal2');
    if (this.downloadData.url) {
      this.percentDone = 0;
      this.showLoader = true;
      this.buttonClick += 1;

      this.downloadFileService
        .downloadFile(this.downloadData.url)
        .subscribe((x) => {
          {
            this.showLoader = true;
            this.downloadProgress(x);
          }
        },
          (error) => {
            this.showLoader = false;
            this.loggingservice.logException(error);
            console.log(error + 'downlaod failed')
          });
    }
  }

  goToLink() {
    window.open('', '_blank');
  }

  goToPaymentsPage() {
    localStorage.setItem("OS", this.purchase.OSType)
    window.open(
      `${window.location.origin}/subscription?promoCode=${this.purchase.promoCode}&zipCode=${this.purchase.registration.zip}`,
      '_blank'
    );
  }

  toggleSeeMore(anchorRef) {
    if (anchorRef.innerHTML === 'See more') {
      this._contentBody.nativeElement.style.height =
        this._contentBody.nativeElement.firstChild.offsetHeight + 'px';
      anchorRef.innerHTML = 'See less';
    } else {
      this._contentBody.nativeElement.style.height = '0px';
      anchorRef.innerHTML = 'See more';
    }
  }

  checkAutorenewalStatus(skuResponse) {
    return new Promise((resolve, reject) => {
      this.purchaseService.checkForAutorenewal().subscribe(
        (data) => {
          if (skuResponse === undefined) {
            skuResponse = { sku: "" }
          }
          if (data?.autoRenewalIndicator) {
            this.autoRenewal = data?.autoRenewalIndicator;
          } else {
            this.autoRenewal = false;
          }
          resolve({
            autoRenewal: data?.autoRenewalIndicator,
            priceGroupKey: data.priceGroupKey,
            sku: skuResponse.sku,
            device:
              `(${this.purchase.OSType === 'M' ? 'Mac' : 'Windows'})` || '',
            taxYear: data.taxYear,
            zipCode: this.purchase.registration.zip,
          });
        },
        (error) => {
          reject(error);
          this.loggingservice.logException(error);
        }
      );
    });
  }


   promoBasedOnAlias(aliasFirstChar, aliasLastTwoChars, promo) {
  
     let aliasFirstCharUpper = aliasFirstChar.toUpperCase();
     let aliasLastTwoCharsUpper = aliasLastTwoChars.toUpperCase();

    switch (aliasLastTwoCharsUpper) {
      // basic
      case 'RC':
      case 'WC':
      case 'CC':
   
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B1}`;
        break;
      //Best Buy
      case 'BF':
        if (aliasFirstCharUpper != 'C') {
          this.navigateRoute.navigate(['']);
          break;

        }

      case 'BC':
        this.isRouteBestBuy = true;
        this.bookImg += 
        `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_B1_blank_rgb}`;
        break;

      // delux
      case 'RD':
      case 'CD':
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;
        break;
      //Best Buy
      case 'DF':
        if (aliasFirstCharUpper != 'C') {
          this.navigateRoute.navigate(['']);
          break;

        }
        case 'BD':
        this.isRouteBestBuy = true;
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_D1_blank_rgb}`;
        break;

      // delux + state
      case 'RJ':
      case 'NJ':
      case 'CJ':
 
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;
        break;
      case 'HJ':
        this.isRouteCHJ = true;
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;

        break;
      //Best Buy
      case 'DA':
        if (aliasFirstCharUpper != 'C') {
          this.navigateRoute.navigate(['']);
          break;

        }
      case 'BJ':
        this.isRouteBestBuy = true;
      
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_D2_blank_rgb}`;
        break;

      // premium
      case 'RM':
      case 'WM':
      case 'CM':
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_P2}`;
        break;
      //Best Buy
      case 'PA':
        if (aliasFirstCharUpper != 'C') {
          this.navigateRoute.navigate(['']);
          break;

        }
      case 'BM':
        this.isRouteBestBuy = true;
     
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_P2_blank_rgb}`;
        break;

      // business
      case 'RH':
      case 'WH':
      case 'CH':
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B2}`;
        break;
      // Walmart

      case 'MD':
        if (aliasFirstCharUpper == 'E')
        {
          this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;
          break;

   
        } else {
          this.navigateRoute.navigate(['']);
        

          break;

        }
  


        
      case 'WD':


        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;
        break;

      
      case 'MJ':
        if (aliasFirstCharUpper == 'E') {
    
          this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;
          break;

      
        } else {
        
          this.navigateRoute.navigate(['']);
          break;
        }

      case 'WJ':
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;
        break;
      //Best Buy
      case 'PH':
        if (aliasFirstCharUpper != 'C') {
          this.navigateRoute.navigate(['']);
          break;

        }

        case 'BH':
        this.isRouteBestBuy = true;
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_H2_blank_rgb}`;
        break;

      // Amazon
      case 'ZC':
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B1}`;
        //'Amazon_B1.png';

        break;
      case 'ZD':
    
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;
        //'Amazon_D1.png';

        break;
      case 'ZJ':
    
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;
        // 'Amazon_D2.png';

        break;
      case 'ZM':
      
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_P2}`;
        //'Amazon_P1.png';

        break;

      case 'ZH':
        this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B2}`;
        //'Amazon_P1_B1.png';

        break;

      default:
        this.navigateRoute.navigate(['']);
        break;
    }
  }




  

promoCodeCheck(promo){

  let aliasFirstCharUpper = promo[0].toLocaleUpperCase();
  let aliasLastTwoChars = promo.substring(1); // Get the last two characters
  let aliasLastTwoCharsUpper = aliasLastTwoChars.toLocaleUpperCase();

  
  switch (aliasLastTwoCharsUpper) {

    case 'BF':
    case 'DF':
    case 'DA':
    case 'PA':
    case 'PH':
      if (aliasFirstCharUpper == 'C') { this.isRouteBestBuy = true }
      else { this.isRouteBestBuy = false}


case 'BC':
case 'BD':
case 'BJ':
case 'BM':
    case 'BH':
      this.isRouteBestBuy = true;



}
return this.isRouteBestBuy;
  }
}
declare function _dd(arg0: string, contextData: any): any;
